import routes from '@config/routes';
import instance from '@instance';

interface UserExistResponse {
  exists: boolean;
  status: 'regular' | 'partial';
  userId: number;
}

export type CheckUserExistingResult = UserExistResponse | string;

export const checkUserExisting = async (
  values: Record<string, any>
): Promise<CheckUserExistingResult> => {
  const body = JSON.stringify(values);

  try {
    const response = await instance.server(routes.userExist, {
      body,
    });

    if (!response.ok) {
      const errorData = await response.json();
      return errorData || 'An error occurred while checking user existence';
    }

    return await response.json();
  } catch (error) {
    return error instanceof Error
      ? error.message
      : 'An unexpected error occurred';
  }
};
