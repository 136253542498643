'use client';
import { useNewsletterTag } from '@hooks/use-newsletter-tag';
import { FollowList } from '@interfaces/auth';
import { PerformerResponse } from '@v2/types';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { TeamListItem } from './TeamListItem';

interface Props {
  items: PerformerResponse[];
  favorites?: FollowList;
  userToken?: string;
}

export const PopularTeamsList = ({ items, favorites, userToken }: Props) => {
  const [favoritiesList, setFavoritiesList] = useState(favorites);

  const { addTagToUser, removeTagFromuser } = useNewsletterTag();

  const { update, data } = useSession();

  const userEmail = data?.user.email;

  const onFavoriteUpdate = async (item: FollowList[number]) => {
    await addTagToUser({ email: userEmail!, tag: item.name });
    await update({
      follow: [
        ...(favoritiesList || []),
        { id: item.id, type: 'p', name: item.name },
      ],
    });
    setFavoritiesList((oldValue) => {
      return [
        ...(oldValue || []),
        { id: item.id, type: 'performer', name: item.name },
      ];
    });
  };

  const onRemoveFavoriteUpdate = async (item: FollowList[number]) => {
    const removedFollowList = favoritiesList?.filter(
      (fav) => fav.id !== item.id
    );

    await removeTagFromuser({ email: userEmail!, tag: item.name });

    await update({
      follow: removedFollowList,
    });

    setFavoritiesList(removedFollowList);
  };

  return (
    <>
      {[].map((performer) => {
        const isFavorite = favoritiesList?.find(
          (item) => item.id === performer.id
        );
        return (
          <TeamListItem
            key={performer.id}
            isFavorite={isFavorite}
            performer={performer}
            onFavoriteUpdate={onFavoriteUpdate}
            onRemoveFavoriteUpdate={onRemoveFavoriteUpdate}
            userToken={userToken}
          />
        );
      })}
    </>
  );
};
