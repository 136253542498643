import { NuqsAdapter } from 'nuqs/adapters/next/app';

import AuthContextProvider from '@contexts/auth-context';
import { ClarityContext } from '@contexts/clarity-context';
import { CurrencyContextProvider } from '@contexts/currency-context';
import { TooltipProvider } from '@v2/ui';
import { PropsWithChildren, Suspense } from 'react';

export function Providers({ children }: PropsWithChildren) {
  return (
    <AuthContextProvider>
      <TooltipProvider>
        <CurrencyContextProvider>
          <ClarityContext>
            <NuqsAdapter>
              <Suspense>{children}</Suspense>
            </NuqsAdapter>
          </ClarityContext>
        </CurrencyContextProvider>
      </TooltipProvider>
    </AuthContextProvider>
  );
}
