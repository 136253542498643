import { Close, SearchMD } from '@v2/ui';
import { type SearchResultsProps } from '../../services/format-search-result-data';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchResults } from '../SearchResults/SearchResults';

interface Props {
  isSearchMobileInputOnFocus: boolean;
  searchResults?: SearchResultsProps[];
  inputValue: string;
  isLoadingResults: boolean;
  isMobileSmallVersion: boolean;
  toggleMobileInputFocus: () => void;
  onChangeInput: (value: string) => void;
}

export const MobileSearchInput = ({
  isSearchMobileInputOnFocus,
  searchResults,
  inputValue,
  isLoadingResults,
  onChangeInput,
  toggleMobileInputFocus,
  isMobileSmallVersion,
}: Props) => {
  return (
    <>
      {isMobileSmallVersion ? (
        <button onClick={toggleMobileInputFocus}>
          <SearchMD />
        </button>
      ) : (
        <div className="flex h-14 items-center rounded-xl bg-white px-5 text-gray-500 md:hidden">
          <button
            onClick={toggleMobileInputFocus}
            className="flex w-full flex-row items-center text-sm text-gray-500"
          >
            <SearchMD />
            <h4 className="ml-4 font-medium">Search event, team or location</h4>
          </button>
        </div>
      )}

      <div
        className={`relative md:hidden ${isSearchMobileInputOnFocus ? '' : 'hidden'}`}
      >
        <div className="fixed left-0 top-0 z-20 h-[100svh] w-full bg-white">
          <div className="flex h-fit items-center justify-center px-4">
            <div className="flex-1 text-gray-500">
              <SearchInput
                inputValue={inputValue}
                className="flex flex-1 text-gray-800 outline-none"
                onChangeInput={(e) => {
                  onChangeInput(e.target.value);
                }}
              />
            </div>

            <button
              type="button"
              className="text-gray-800"
              onClick={toggleMobileInputFocus}
            >
              <Close />
            </button>
          </div>
          <SearchResults
            isLoading={isLoadingResults}
            searchResult={searchResults}
            isSearchInputOnFocus
            inputValue={inputValue}
          />
        </div>
      </div>
    </>
  );
};
