'use client';

import { useThemeConfigStore } from '@store/theme-config-store';
import { useClickAway } from '@uidotdev/usehooks';
import { ChevronDown } from '@v2/ui';
import Link from 'next/link';
import { useState } from 'react';

export enum DESTINATION {
  UNITED_STATES = 'UNITED_STATES',
  CANADA = 'CANADA',
}

export const genreCategoriesList = [
  {
    label: 'United states',
    value: DESTINATION.UNITED_STATES,
  },
  {
    label: 'Canada',
    value: DESTINATION.CANADA,
  },
];

const locations = [
  { city: 'Boston', state: 'Massachusetts' },
  { city: 'Chicago', state: 'Illinois' },
  { city: 'Fort Lauderdale', state: 'Florida' },
  { city: 'Fort Myers', state: 'Florida' },
  { city: 'Las Vegas', state: 'Nevada' },
  { city: 'Los Angeles', state: 'California' },
  { city: 'Miami', state: 'Florida' },
  { city: 'New York', state: 'New York' },
  { city: 'Orlando', state: 'Florida' },
  { city: 'Phoenix', state: 'Arizona' },
  { city: 'San Francisco', state: 'California' },
  { city: 'San Diego', state: 'California' },
  { city: 'Tampa', state: 'Florida' },
  { city: 'Washington', state: 'DC' },
];

export const DestinationsDropdown = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<DESTINATION>(
    DESTINATION.UNITED_STATES
  );

  const cities = useThemeConfigStore((state) => state.cities);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const primaryColor = useThemeConfigStore((state) => state.primaryColor);
  const tenantSlug = useThemeConfigStore((state) => state.tenantSlug);

  const dropdownMenuRef = useClickAway<HTMLDivElement>(() => {
    setIsDropdownOpen(false);
  });

  const selectedLabel = genreCategoriesList.find(
    (val) => val.value === selectedMenuItem
  )!;

  if(!cities?.length) return null;

  const usCities = cities.filter((city) => city.country === 'USA');
  const canadianCities = cities.filter((city) => city.country === 'Canada');

  const selectedList =
    selectedMenuItem === DESTINATION.UNITED_STATES ? usCities : canadianCities;

  return (
    <div ref={dropdownMenuRef}>
      <button
        onClick={() => setIsDropdownOpen((oldValue) => !oldValue)}
        className={`flex items-center gap-2 rounded-lg border-transparent ${isDropdownOpen && 'border-2'}`}
      >
        Destinations <ChevronDown stroke={'white'} size="20" />
      </button>

      <div className="relative">
        <div
          className={`${isDropdownOpen ? 'flex' : 'hidden'} absolute z-10 mt-3 w-[800px] flex-col rounded-lg bg-white text-gray-800 shadow-lg`}
          ref={dropdownMenuRef}
        >
          <div className="w-full border-b border-gray-200 px-8 pt-5">
            <ul className="no-scrollbar flex gap-x-8 overflow-scroll">
              {genreCategoriesList.map(({ label, value }) => (
                <li
                  className={`pb-5 ${selectedMenuItem === value ? 'border-b-2 border-primary-500' : ''} `}
                  key={value}
                >
                  <button
                    onClick={() => {
                      setSelectedMenuItem(value);
                    }}
                    className={`flex flex-row items-center gap-2 text-nowrap text-base font-semibold ${selectedMenuItem === value ? 'text-primary-500' : ''}`}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <ul className="grid w-full grid-flow-row auto-rows-max grid-cols-3 gap-x-8 gap-y-3 p-8 text-left">
            {selectedList.map(({ name, state, stateShort, lat, lon }) => (
              <li key={name}>
                <Link
                  href={`/destination/${name.toLowerCase()}-${stateShort}?longitude=${lon}&latitude=${lat}&partnerSlug=${tenantSlug}`}
                  key={name}
                  target="_blank"
                  className="text-base font-semibold text-gray-800"
                >
                  <span className="text-md font-semibold text-gray-700">
                    {name}
                  </span>
                  <br />
                  <span className="text-sm font-medium text-gray-500">
                    {state}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
