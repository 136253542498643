'use client';

import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { LoggedInUserProps } from '@interfaces/auth';
import { useThemeConfigStore } from '@store/theme-config-store';
import { v2Links } from '@utils/navigation/links';
import { UserNav } from '@v2/containers';
import { CurrencyDropdown } from '@v2/containers/CurrencyPicker/CurrencyDropdown';
import { MobileMenu } from '@v2/containers/Header/components/MobileMenu/MobileMenu';
import { HeaderSearch } from '@v2/containers/Search/HeaderSearch';
import { typographyVariants } from '@v2/ui';
import { cn } from '@v2/utils';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { DestinationsDropdown } from '../partner/[slug]/components/DestinationsDropdown';

type HeaderBgVariant = 'white' | 'black';
export type HeaderProps = {
  user: LoggedInUserProps | null;
  variant?: HeaderBgVariant;
  withSearchbar?: boolean;
  children?: React.ReactNode;
};
export const WhiteLabelHeader = (props: HeaderProps) => {
  const { user, variant = 'white', withSearchbar = false } = props;

  const isBlackMode = variant === 'black';

  const buttonStyle = isBlackMode
    ? 'bg-white border border-gray-300 text-gray-800 '
    : 'bg-slate-500/25 border-transparent text-white';

  const partnerSlug = useThemeConfigStore((state) => state.tenantSlug);
  const searchParams = useSearchParams();
  const partnerSLugParam = searchParams.get('partnerSlug');
  const md = useBetterMediaQuery('(min-width: 768px)');

  return (
    <header className="relative z-10 mx-auto mb-8 mt-0 flex h-[72px] w-full max-w-7xl items-center justify-between px-4 md:mb-20 md:h-24 xl:px-0">
      <div className="flex">
        <Link href={`/partner/${partnerSlug ?? partnerSLugParam}`}>
          <Image
            src={'/white-label/porter-logo.png'}
            width={123}
            height={md ? 48 : 40}
            alt="Porter"
            className="mr-6"
          />
        </Link>
        <Image
          src={'/white-label/porter-secondary-logo.webp'}
          width={md ? 83 : 69}
          height={md ? 48 : 40}
          alt="Porter"
          className="mr-10"
        />
        <div className="hidden flex-row gap-x-5 md:flex">
          <HeaderSearch variant={variant} />
          <nav
            className={`font-semibold ${isBlackMode ? 'text-gray-800' : 'text-white'}`}
          >
            <ul className="flex h-full items-center gap-3 text-center">
              <li className="inline">
                <DestinationsDropdown />
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        className={`flex gap-4 ${isBlackMode ? 'text-gray-800' : 'text-white'}`}
      >
        <div className="hidden md:block">
          <CurrencyDropdown variant={variant} />
        </div>

        {!user && (
          <Link
            href={v2Links.login}
            className="hidden md:block"
            prefetch={false}
          >
            <button
              className={cn(
                `${typographyVariants({ size: 'tmd' })} h-12 w-24 rounded-lg ${buttonStyle} `
              )}
            >
              Sign in
            </button>
          </Link>
        )}
        <MobileMenu user={user} />

        {user && <UserNav user={user} variant={variant} />}
      </div>
    </header>
  );
};
