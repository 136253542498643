import { City } from '@v2/types';
import { create } from 'zustand';

interface ThemeConfigSTore {
  primaryColor: string;
  cities: City[];
  tenantSlug: string;
  isTenantEnabled: boolean;
}

export const useThemeConfigStore = create<ThemeConfigSTore>((set) => ({
  primaryColor: '#D5223C',
  cities: [],
  tenantSlug: '',
  isTenantEnabled: false,
}));
