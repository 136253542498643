import { useThemeConfigStore } from '@store/theme-config-store';
import { ChevronDown, ChevronUp } from '@v2/ui';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';

const leaguesList = ['MLB', 'NBA', 'NFL', 'NHL', 'MLS', 'Others'] as const;

const SportsTitle = (
  <h3 className="py-4 text-base font-bold text-gray-800">Destinations</h3>
);

const CategoryTitle = ({ label }: { label: string }) => (
  <div className="flex items-center gap-3 text-gray-800">
    <h3 className="py-4 text-base font-bold text-gray-800">{label}</h3>
  </div>
);

export const MobileCollapseDestinations = () => {
  const cities = useThemeConfigStore((state) => state.cities);
  const partnerSlug = useThemeConfigStore((state) => state.tenantSlug);

  if (!cities?.length) return null;

  const usCities = cities.filter((city) => city.country === 'USA');
  const canadianCities = cities.filter((city) => city.country === 'Canada');
  return (
    <MobileCollapseItem trigger={SportsTitle}>
      {['USA', 'Canada'].map((country) => {
        const cityList = country === 'USA' ? usCities : canadianCities;
        return (
          <MobileCollapseItem
            key={country}
            trigger={<CategoryTitle label={country} />}
          >
            {cityList.map(({ name, stateShort, lon, lat }, index) => (
              <div
                key={name}
                className={`mb-8 flex flex-col ${index === 0 ? 'pt-4' : ''}`}
              >
                <Link
                  className="mb-2 text-sm font-semibold"
                  prefetch={false}
                  href={`/destination/${name.toLowerCase()}-${stateShort}?longitude=${lon}&latitude=${lat}&partnerSlug=${partnerSlug}`}
                  key={name}
                  target="_blank"
                >
                  {name}, {stateShort}
                </Link>
              </div>
            ))}
          </MobileCollapseItem>
        );
      })}
    </MobileCollapseItem>
  );
};

interface MobileCollapseItemProps {
  trigger: string | React.JSX.Element;
  children: React.ReactNode;
}

const MobileCollapseItem = ({ trigger, children }: MobileCollapseItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const baseClassName = clsx('flex flex-col w-full', {
    hidden: !isOpen,
  });
  return (
    <>
      <button
        className="flex w-full items-center justify-between text-left"
        onClick={() => setIsOpen((old) => !old)}
      >
        {trigger}
        {isOpen ? <ChevronUp size="24" /> : <ChevronDown size="24" />}
      </button>
      <div className={baseClassName}>{children}</div>
    </>
  );
};
